@import "styles/colors";

.common-input {
  display: block;
  width: 100%;
  padding: 0.815rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: $input-text;
  background-color: $input-bg;
  border: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.common-input-focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}

.input-error {
  border: 1px solid #dc3545 !important;
}

.input-error-msg {
  color: #dc3545;
  display: block;
  margin-top: 5px;
  font-size: 16px;
}
