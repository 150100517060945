@import "styles/colors";

.breadcrum-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1.5rem;

  .breadcrum-item {
    color: #d4d4d4;

    .bi-dot-active {
      color: $primary;
    }

    .breadcrum-icon {
      color: $primary;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.4rem;
      border: 1px solid $primary;
      border-radius: 50%;
      height: 32px;
      width: 32px;
    }
  }
}
