.dob-inputs-wrapper {
  display: grid;
  grid-template-columns: 32% 32% 32%;
  align-items: end;
  justify-content: space-between;
  margin-bottom: 0.6rem;
}

.datepicker-wrapper {
  display: flex;
  gap: 1rem;
}
