@import "styles/colors";

.photo-select-container {
  .image-upload {
    width: 150px;
    height: 150px;
    border: 1px dashed #828282;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .photo-error-wrapper {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      padding: 6px;
      text-align: center;
      background-color: hwb(0deg 0% 89% / 80%);

      .photo-error {
        color: #ffffff;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    label {
      cursor: pointer;

      svg {
        font-size: 2rem;
        color: $primary;
      }
    }

    .img-remove-btn {
      position: absolute;
      top: -12px;
      right: 0;
      padding: 0;
      border: 0;
      background: none;
      font-size: 1.5rem;
      color: $primary;
      cursor: pointer;
    }
  }

  .custom-file-input {
    color: transparent;
    display: contents;
  }

  .custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
  }
}
