.phone-inputs {
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;

  .input-container {
    margin-bottom: 0 !important;

    label {
      margin-bottom: 0 !important;
    }
  }
}

.action-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  margin-top: auto;
  padding-top: 2rem;

  .h-button {
    min-width: 250px;
  }
}
