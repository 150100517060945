@import "styles/colors";

.radio-btn-group {
  display: flex;

  .radio {
    margin: 0 0.25rem;
    position: relative;

    .redio-check {
      position: absolute;
      z-index: 1;
      top: 11px;
      left: 10px;

      svg {
        font-size: 1.2rem;
      }
    }

    label {
      background: #fff;
      border: 1px solid #ddd;
      padding: 0.4rem 1.25rem 0.5rem;
      border-radius: 33px;
      cursor: pointer;
      color: #444;
      transition: box-shadow 400ms ease;
      display: block;

      &:hover {
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
          0 2px 10px 0 rgba(0, 0, 0, 0.12);
      }
    }

    input[type="radio"] {
      display: none;
    }

    input[type="radio"]:checked + label {
      background: rgba(149, 117, 205, 0.2);
      color: #333333;
      border: 2px solid $primary;
    }
  }
}
