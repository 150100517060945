.otp-inputs {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 2rem;
  gap: 1rem;

  .retry-otp {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .ref-code-wrapper {
    text-align: center;

    .typography-body {
      margin-bottom: 5px;
    }

    .h-input {
      text-align: center;
    }
  }
}
