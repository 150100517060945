.page-loading-container {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 10000;
  background-color: rgb(0 0 0 / 25%);

  h3 {
    color: #ffffff;
    margin-top: 1rem;
  }
}
