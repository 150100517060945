@import "styles/input";

.textarea-container {
  width: 100%;

  .h-textarea {
    @extend .common-input;
    width: 100% !important;
  }

  .textarea-char-count {
    float: right;
    margin-top: 5px;
  }
}
