@import "styles/input";

.input-container {
  width: 100%;

  .h-input {
    @extend .common-input;
  }
}

.css-1wa3eu0-placeholder {
  color: #333333 !important;
}
