.typography-header {
  // font-family: 'Gimbal Grotesque';
  font-weight: 500;
  margin-bottom: 16px;
}

// .typography-body {
//   font-family: 'Inter';
// }

.typography--variant-h1 {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.typography--variant-h2 {
  font-size: 1.875rem;
  line-height: 2.5rem;
}

.typography--variant-h3 {
  font-size: 1.5rem;
  line-height: 2rem;
}

.typography--variant-h4 {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.typography--variant-h5 {
  font-size: 18px;
  line-height: 28px;
}

.typography--variant-baseRegular {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
}

.typography--variant-baseMedium {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
}

.typography--variant-smallRegular {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
}

.typography--variant-smallRegular {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
}

.typography--variant-smallMedium {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
}

.typography--variant-smallLink {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  text-decoration: underline;
  padding: 0;
  margin: 0;
}

.typography--variant-smallBold {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 600;
  padding: 0;
  margin: 0;
}

.typography--variant-extraSmallRegular {
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 400;
  padding: 0;
  margin: 0;
}

.typography--variant-extraSmallMedium {
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 500;
  padding: 0;
  margin: 0;
}

.typography--color-black {
  color: #333333;
}

.typography--color-danger {
  color: #dc3545;
}
