.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1 {
  display: block;
  font-size: 2em;
}

h2 {
  display: block;
  font-size: 1.5em;
}

h3 {
  display: block;
  font-size: 1.17em;
}

h4 {
  display: block;
}

h5 {
  display: block;
  font-size: 0.83em;
}

h6 {
  display: block;
  font-size: 0.67em;
}
