@import "styles/colors";

.alert-message-container {
  display: flex;
  align-items: center;
  gap: 7px;
  padding: 8px 12px;
  border-radius: 4px;

  svg {
    font-size: 1.3rem;
    position: relative;
    top: 2px;
  }
}

.variant-error {
  border: 1px solid #f5c6cb;
  background-color: #f8d7da;
  color: #721c24;
}

.variant-success {
  color: #155724;
  background-color: #d4edda;
  border: 1px solid #c3e6cb;
}
