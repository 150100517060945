.radio-inputs-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.5rem;
  gap: 0.4rem;
}

.habbits-item {
  margin-bottom: 25px;
}
