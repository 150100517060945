@import "styles/_colors.scss";

.react-tel-input {
  font-family: inherit;
  font-size: 1rem;
  width: auto;
}

.react-tel-input .form-control {
  height: 50px;
  padding-left: 55px;
  width: 130px;
  font-size: 1rem;
  background-color: $input-bg;
  border: 1px solid $input-bg;
}

.react-tel-input .flag-dropdown {
  background-color: $input-bg;
  border: 1px solid $input-bg;
}

.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
  background-color: $input-bg;
}

.react-tel-input .selected-flag {
  padding: 0 0 0 16px;
}
