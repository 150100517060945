.splash-screen {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-top: 20%;

  .splash-footer {
    margin-top: auto;

    h6 {
      margin-top: 10%;
    }
  }

  .h-button.primary {
    margin-top: 10%;
  }
}
