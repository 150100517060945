.privacy-policy-container {
  padding: 2rem 0;
  margin: auto;
  border-radius: 8px;
  padding-bottom: 40px !important;
  max-width: 960px !important;

  .pp-about {
    margin-bottom: 2rem;
  }

  ol li {
    margin: 1.5rem 0;

    h2 {
      margin-top: 2rem;
      margin-bottom: 1rem;
      font-size: 18px;
      font-weight: 700;
      color: #404040;
    }
  }

  p {
    font-size: 1rem;
  }
}
