@import "styles/colors";

.h-button {
  padding: 0.75rem 2rem;
  font-size: 1.25rem;
  line-height: 1.5;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  .spinner {
    margin: auto;
    width: 70px;
    text-align: center;
  }

  .spinner > div {
    width: 10px;
    height: 10px;
    background-color: #000000;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  }

  .spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }

  .spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }

  @-webkit-keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
    }
  }

  @keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
}

.h-button:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.h-button.primary {
  background-color: $primary;
  color: #ffffff;
  font-weight: 600;
  border-radius: 55px;
  font-size: 16px;

  &:hover {
    background-color: #7955b7;
  }
}

.h-button.secondary {
  background-color: transparent;
  color: #000000;
  font-weight: 600;
  border-radius: 55px;
  font-size: 16px;
  border: 2px solid $primary;

  &:hover {
    background-color: #23272a;
    color: #ffffff;
    border: 2px solid #23272a;
  }
}

.h-button.outline {
  background-color: transparent;
  color: $primary;
  font-weight: 600;
  border-radius: 55px;
  font-size: 16px;
  border: 2px solid $primary;

  &:hover {
    background-color: $primary;
    color: #ffffff;
  }
}

.h-button.text {
  background-color: transparent;
  color: #333333;
  font-weight: 600;
  border-radius: 0;
  font-size: 16px;
  border: none;
  text-decoration: underline;

  &:hover {
    background-color: transparent;
    color: #000000;
  }
}

.shape-square {
  border-radius: 8px !important;
}
