.payment_page {
  background-color: #f6f6f6;
  padding: 2rem 0 2rem;
  min-height: 100vh;
}

.paymet_container {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 2rem 1rem;
}

.payment_section_wrapper {
  margin-top: 3rem;
  display: flex;
  gap: 2rem;
  padding: 0 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.pay_user_details {
  width: 60%;
}

.payment_type {
  width: 40%;
}

.payment_user_details {
  text-align: center;
  margin-bottom: 1rem;
}

.membership_details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  flex-wrap: wrap;
}

.payment_page p {
  font-size: 1rem;
}

.payment_amount {
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
  margin-bottom: -0.5rem;
  border-top: 1px solid #d3d3d3;
  padding-top: 1rem;
}

.payment_info {
  background-color: #f1f2f7;
  padding: 1.5rem 1rem;
  border-radius: 8px;
  margin-bottom: 1rem;

  p {
    line-height: 1.5;
  }
}

.payment_type a {
  margin-bottom: 0.5rem;
}

.payment_type {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.pay_button_wrapper p {
  text-align: center;
  line-height: 1.3;
}

.pay_button_wrapper:last-child p {
  margin-bottom: 0;
}

.bank_details_item {
  display: flex;
  gap: 1rem;
}

.bank_details {
  h6 {
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }
}

.bank_details:first-child {
  width: 30%;
}

.bank_details:last-child {
  width: 70%;
  text-align: right;
}

.bank_details:last-child h6 {
  font-weight: 400;
}

.pay_bank_details {
  width: 60%;
}

.pay_bank_details h5 {
  margin-bottom: 1.5rem;
  font-size: 18px;
}

.paymet_container.bank-transfer .h2-title {
  margin-bottom: 1.2rem;
}

.bank-transfer {
  .membership_details {
    h3 {
      font-weight: 400;
    }
  }
}

.bank_pay_info {
  width: 60%;
  background-color: #448aff1f;
  padding: 1rem;
  border-radius: 8px;
}

.h2-title {
  font-weight: 800;
  line-height: 120%;
  font-size: 48px;
  vertical-align: baseline;
  color: #23272a;
  font-family: "Open Sans", sans-serif;
}

.text-center {
  text-align: center !important;
}

.pay_button_wrapper {
  text-align: center;
  margin-bottom: 1rem;
}

.duration-text h3 {
  font-weight: 400;
}

.duration-select-wrapper {
  width: 35%;
  min-width: 130px;
}

@media (max-width: 990px) {
  .pay_user_details,
  .pay_bank_details,
  .bank_pay_info {
    width: 95%;
  }

  .payment_type {
    width: 70%;
  }

  .hero_title {
    font-size: 50px;
    margin-bottom: 1.5rem;
  }

  .topnav .nav-links,
  .nav_right_wrapper {
    display: none;
  }
  .topnav a.icon {
    display: block;
  }

  .topnav.responsive {
    position: relative;
  }

  .topnav.responsive .nav-links,
  .topnav.responsive .nav_right_wrapper {
    display: block;
  }

  .nav-wrapper {
    position: absolute;
    top: 55px;
    left: 0;
    width: 100%;
    border-radius: 14px;
    background-color: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .nav_right_wrapper {
    position: absolute;
    top: 268px;
    background-color: #ffffff;
    width: 100%;
    border-radius: 0 0 10px 10px;
    text-align: center;
    border-left: 2px solid #b3b3b3;
    border-right: 2px solid #b3b3b3;
    border-bottom: 2px solid #b3b3b3;
  }

  .nav-header nav ul {
    padding: 1rem 2rem;
  }

  .nav-header nav ul li a {
    color: #000000;
    text-align: center;
    padding: 0.3rem 0rem;
  }

  .hero_wrapper {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }

  .hero_image {
    padding-left: 0;
    max-width: 500px;
  }

  .hero_title,
  .hero_main_text p {
    text-align: center;
  }

  .hero_action_buttons {
    justify-content: center;
  }

  .hero_logo {
    display: none;
  }

  .hero_logo_mobile {
    display: block;
    text-align: center;
    margin-bottom: 2rem;
  }

  .hero_logo_mobile img {
    max-width: 250px;
    width: 100%;
  }
}

@media (max-width: 768px) {
  .pay_user_details,
  .pay_bank_details,
  .bank_pay_info {
    width: 95%;
  }

  .payment_type {
    width: 80%;
  }

  .payment_section_wrapper {
    padding: 0;
  }

  .h2-title {
    font-size: 35px;
  }

  #error-alert {
    width: 96%;
  }

  .hero_title {
    font-size: 40px;
  }
}

@media (max-width: 576px) {
  .pay_user_details,
  .pay_bank_details,
  .bank_pay_info {
    width: 100%;
  }

  .h2-title {
    font-size: 32px;
  }

  .hero_action_buttons {
    flex-wrap: wrap;
  }

  .hero_title {
    font-size: 28px;
  }

  .hero_main_text p {
    font-size: 1.1rem;
  }

  .hero_logo_mobile img {
    max-width: 150px;
  }

  .poruwa-wrapper img {
    max-width: 200px;
  }

  .poruwa-section .about-poruwa-main {
    font-size: 16px;
    margin-bottom: 5px;
  }

  .poruwa-section {
    padding-top: 1rem;
  }
}

@media (max-width: 400px) {
  .payment_section_wrapper {
    gap: 1rem;
  }
}

@media (max-width: 360px) {
  .membership_details {
    align-items: flex-start;
    flex-direction: column;
    gap: 0.4rem;
  }

  .secondary_button {
    height: 56px;
    font-size: 16px;
  }

  .payment_amount h5 {
    font-size: 1.2rem;
  }
}
