.register-wrapper {
  background-color: #e5e5e5;
  min-height: calc(100% - 80px);
  display: flex;
  justify-content: center;
  padding-top: 2rem;

  .register-inner {
    width: 680px;
    background-color: #ffffff;
    padding: 2rem;
    margin-bottom: 2rem;
    border-radius: 8px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
      rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

    .flow-step {
      display: flex;
      flex-direction: column;
      height: 100%;

      .create-flow-content {
        .input-container {
          margin-bottom: 0.8rem;

          label {
            margin-bottom: 4px;
            display: block;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .register-wrapper {
    align-items: unset;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    .register-inner {
      width: 98%;
      height: unset;
    }
  }
}

@media screen and (max-width: 570px) {
  .register-wrapper .register-inner {
    padding: 2rem 1rem;
  }

  .create-flow-content {
    h1.typography-header {
      font-size: 1.8rem;
      line-height: normal;

      br {
        display: none;
      }
    }
  }

  .photo-select-container .image-upload {
    width: 100px;
    height: 100px;
  }
}
