@import "styles/colors";

.success-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  .success-footer {
    margin-top: auto;
  }

  svg {
    font-size: 4rem;
    color: $success-green;
  }
}
